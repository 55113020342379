import {Menu,Button } from 'antd';
import { Link } from 'react-router-dom';
import { useState ,useEffect} from 'react';
import i18next from 'i18next';
import "./Nav.css";


const Nav = () => {
  const [button,setButton]=useState(true)

  const handleLanguage=(e)=>{
    localStorage.setItem('lang',e.currentTarget.value)
    window.location.reload();
  }
  const lang=localStorage.getItem('lang') 
  useEffect(()=>{
    if(lang==='zh_TW'){
      setButton(!button)
    }
  },[])
  
  return (
    <div className='nav-container'>
      <a href="https://www.orientaldaily.com.my/">
        <div className='odaily-logo'></div>
      </a>

      <div className='menu-item'>
        <Menu theme="light" mode='horizontal' >
          <Menu.Item className="menu-list" key="1"><Link to="/">{i18next.t('2023马来西亚产业大奖')}</Link></Menu.Item>
        </Menu>
      </div>

        <div className="button-convert">
          <Button type="text" style={button ? {color:'#cb0c13',backgroundColor:'rgb(220,220,220)',opacity:'0.8',borderRadius:'8px' } :null}  size="small" onClick={handleLanguage} value="zh_CN">简</Button>
          <Button type="text" style={!button ? {color:'#cb0c13',backgroundColor:'rgb(220,220,220)',opacity:'0.8',borderRadius:'8px' } :null} size="small" onClick={handleLanguage} value="zh_TW">繁</Button>
        </div>

    </div>
  )}

export default Nav