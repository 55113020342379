import {Nav,Home,Footer} from './components/index';
import {Routes,Route} from 'react-router-dom';

const App = () => {
  return (
    <div className='app'>
      <div className="navbar">
        <Nav/>
      </div>
      <div className="main"> 
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="*" element={<p style={{textAlign:'center'}}>Error 404</p>}/>
        </Routes>
      </div>
      <div className='footer'>
        <Footer/>
      </div>
    </div>
  )
}

export default App